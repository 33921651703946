<template>
  <v-card class="card-shadow">
    <div class="card-header-padding card-border-bottom">
      <p class="font-weight-600 text-h3 text-typo mb-0">Blocked domains</p>
    </div>
    <v-card-text class="card-padding py-2">
      <v-list class="py-0">
        <v-list-item
          v-for="(chat, index) in members.items"
          :key="chat.title"
          class="px-0 py-4"
          :class="{ 'border-bottom': index != members.length - 1 }"
        >
          <v-list-item-avatar :size="48" class="my-0 me-5">
            <v-img :alt="`${chat.title} avatar`" :src="chat.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              v-text="chat.title"
              class="text-typo text-h4 font-weight-600 mb-2"
            ></v-list-item-title>
            <span class="text-body">
              <span :class="chat.color" class="me-1">●</span>
              {{ chat.status }}
            </span>
          </v-list-item-content>

          <v-btn
            v-on:click="remove"
            elevation="0"
            :ripple="false"
            class="font-weight-600 text-capitalize btn-primary bg-primary"
            small
            >Remove
          </v-btn>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
// const listUrl = 'http://localhost:8082';
const listUrl = 'https://api.la0.proxypilot.io/api/v1/gblock/list?key=9wDms5pB1NFc1FW74hYaCWQBIJYS5f2bW4Nw5y0k';
// const removeUrl = 'http://localhost:8082/get';
const removeUrl = 'https://api.la0.proxypilot.io/api/v1/gblock/remove?key=9wDms5pB1NFc1FW74hYaCWQBIJYS5f2bW4Nw5y0k&domain=';

let blackList = {items: []}
blackList.items = [
        {
          title: "No data",
          status: "Unknown",
          color: "text-warning",
        },
      ];
function updateList() {
  return fetch(listUrl)
  .then(response => response.text())
  .then(data => {
    console.log(data);
     blackList.items = data.split('<br>').map((item, i) => {
if (i < 5 ) {console.log('ITEM', item);}
       return {
         title: item.replace(/\\/gi, ''),
         status: "Access denied",
         color: "text-danger"
       }
     });

  })
  .catch((e) => {console.log('Fetch error', e); console.log(blackList.substring(0, 100)); blackList.items = [{ 
          title: "No domains",
          status: "Empty",
          color: "text-success",
        }]; console.log(blackList)});
}
updateList();


export default {
  name: "members-card",
  data() {
    return {
      members: blackList
    };
  },
  methods: {
    remove(e) {
      console.log('REMOVED');
      console.log(this);
      console.log(this.members);
      console.log(e);
      let el = e.target;
      while (!el.outerHTML.startsWith('<div')) {
        console.log('GOT PARENT')
        el = el.parentElement;
      }
      const text = el.innerText.split('\n')[0];
      console.log('TEXT', text);



fetch(`${removeUrl}${text}`)
  .then(response => response.json())
  .then(data => {
    console.log(data);
    if (data && data.errorMessage) {
      throw(data.errorMessage);
    } else {
      updateList();
    }

  })
  .catch((e) => {console.log('Fetch error', e)});








    }
  }
};
</script>
