<template>
  <div>
    <header-top-dashboard>
      <v-row class="mt-0">
        <v-col
          cols="12"
          lg="4"
          md="6"
          class="py-0"
          v-for="item in headerCards"
          :key="item.subtitle"
        >
          <v-card class="card-shadow mb-6" :class="item.background">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-white text-uppercase text-h6"
                  >
                    {{ item.subtitle }}
                  </div>
                  <p class="font-weight-600 text-h2 text-white mb-0">
                    {{ item.title }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <!-- <v-btn
                    elevation="0"
                    small
                    max-width="30"
                    min-width="30"
                    height="28"
                    :ripple="false"
                    class="font-weight-600 text-capitalize btn-neutral pa-1 rounded-sm"
                    color="#fff"
                    ><v-icon>fas fa-ellipsis-h</v-icon></v-btn
                  > -->
                </v-col>
              </v-row>

              <v-progress-linear
                class="progress-shadow my-4"
                :value="item.progressValue"
                rounded
                background-color="#fff"
                :color="item.color"
              ></v-progress-linear>

              <a
                href="javascript:;"
                class="text-white font-weight-600 text-body-2 ls-0 no-default-hover text-decoration-none"
                ></a
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row class="mt-0">
        <v-col class="pt-0" lg="12">
          <!-- <table-inline-actions @clicked="onClickChild"></table-inline-actions> -->
          <dark-table></dark-table>
          <!-- <to-do-list></to-do-list> -->
        </v-col>
 
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import { salesChart, ordersChart } from "@/components/Charts/Chart";
import VectorMapCard from "./Widgets/VectorMapCard.vue";
import MembersCard from "./Widgets/MembersCard.vue";
import LightTable from "./Widgets/LightTable.vue";
import ToDoList from "./Widgets/ToDoList.vue";
import ProgressTrack from "./Widgets/ProgressTrack.vue";
import TableInlineActions from "./Widgets/TableInlineActions.vue";
import DarkTable from "./Widgets/DarkTable.vue";

export default {
  name: "Alternative",
  components: {
    HeaderTopDashboard,
    VectorMapCard,
    MembersCard,
    LightTable,
    ToDoList,
    ProgressTrack,
    TableInlineActions,
    DarkTable
  },
  data() {
    return {
      token: localStorage.getItem('jwt'),
      host:  this.$siteConfig.resellerApiHost,
      paths: {
        users: `${this.$siteConfig.resellerApiBasePath}/reseller/users`,
        resellerInfo: `${this.$siteConfig.resellerApiBasePath}/reseller/info`,
      },


      salesChartID: "salesChartID",
      ordersChartID: "ordersChartID",
      headerCards: [
        {
          subtitle: "Available bandwidth",
          title: "67 GB",
          progressValue: "100%",
          color: "#ef4036",
          background: "bg-gradient-primary",
        },
        {
          subtitle: "Active proxy users",
          title: "2/6",
          progressValue: "33%",
          color: "#292B2D",
          background: "bg-gradient-success",
        },
        {
          subtitle: "Suspended proxy users",
          title: "4/6",
          progressValue: "67%",
          color: "#ef4036",
          background: "bg-gradient-danger",
        },
        // {
        //   subtitle: "Actively scraping today",
        //   title: "1/6",
        //   progressValue: "17%",
        //   color: "#292B2D",
        //   background: "bg-gradient-info",
        // },
      ],
      selectedUser: {
        login: "",
        email: '',
        created: '',
        updated: '',
        balance: '',
        description: '',
        active: true,
        status: 'ACTIVE',
      },
      progress: [
        {
          avatar: require("@/assets/img/bootstrap.jpg"),
          title: "Argon Design System",
          color: "#2dce89",
          progressValue: "60",
        },
        {
          avatar: require("@/assets/img/angular.jpg"),
          title: "Angular Now UI Kit PRO",
          color: "#fb6340",
          progressValue: "100",
        },
        {
          avatar: require("@/assets/img/sketch.jpg"),
          title: "Black Dashboard",
          color: "#f5365c",
          progressValue: "72",
        },
        {
          avatar: require("@/assets/img/react.jpg"),
          title: "React Material Dashboard",
          color: "#11cdef",
          progressValue: "90",
        },
        {
          avatar: require("@/assets/img/vue.jpg"),
          title: "Vue Paper UI Kit PRO",
          color: "#2dce89",
          progressValue: "100",
        },
      ],
    };
  },
  methods: {
    getFullInfo: function() {
        fetch(`${this.$data.host}${this.$data.paths.resellerInfo}`, {
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Authorization': `Bearer ${this.$data.token}`
          }
        })
          .then(response => {
            console.log(response);
            if (response.ok) {
              console.log('OK');
              return response.json()
            } else {
              console.log('NOT OK');
              return response.json();
            }
          })
          .then(json => {
            console.log('DATA:', json);

            if (json && json.data) {
              // Header cards
              try {
                this.$data.headerCards[0].title = `${json.data.trafficBalanceString}`
                this.$data.headerCards[1].title = `${json.data.proxyUsers.active.length}/${json.data.proxyUsers.all.length}`
                this.$data.headerCards[1].progressValue = `${Math.round(100 * json.data.proxyUsers.active.length / json.data.proxyUsers.all.length)} %`
                this.$data.headerCards[2].title = `${json.data.proxyUsers.suspended.length}/${json.data.proxyUsers.all.length}`
                this.$data.headerCards[2].progressValue = `${Math.round(100 * json.data.proxyUsers.suspended.length / json.data.proxyUsers.all.length)} %`
                // this.$data.headerCards[3].title = `${json.data.proxyUsers.activeToday.length}/${json.data.proxyUsers.all.length}`
                // this.$data.headerCards[3].progressValue = `${Math.round(100 * json.data.proxyUsers.activeToday.length / json.data.proxyUsers.all.length)} %` ;

                this.$data.selectedUser.login = json.data.proxyUsers.all[0].login;
                this.$data.selectedUser.email = json.data.proxyUsers.all[0].email;
                this.$data.selectedUser.created = new Date(json.data.proxyUsers.all[0].created).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' });
                this.$data.selectedUser.updated = new Date(json.data.proxyUsers.all[0].updated).toLocaleString('default', {year: 'numeric', month: 'long', day: 'numeric' });
                this.$data.selectedUser.balance = json.data.proxyUsers.all[0].trafficBalanceString;
                this.$data.selectedUser.description = `User ID: ${json.data.proxyUsers.all[0].id}`;

              } catch(e) {
                console.log('Parsing error', e);
              }


              // handle data
            } else if (json && json.error) {
              alert(json.error);
            } else {
              throw new Error('Authentication error');
            }

          })
          .catch((e) => {
            console.log('Fetch error', e);
            localStorage.removeItem('jwt');
            localStorage.removeItem('user'); //TODO: fix
            alert('Authentication expired');
            this.$router.push('/login')
            console.log('Fetch error', e)
        });
    },
    onClickChild: function(data) {
      this.$data.selectedUser.login = data.login;
      this.$data.selectedUser.email = 'Unknown';
      this.$data.selectedUser.created = data.date;
      this.$data.selectedUser.updated = 'Unknown';
      this.$data.selectedUser.balance = data.balance;
      this.$data.selectedUser.description = '';
      this.$data.selectedUser.active = data.status == 'ACTIVE' ? true : false;
      this.$data.selectedUser.status = data.status;

      const path = `${this.$data.paths.users}/${data.id}/info`;
      fetch(`${this.$data.host}${path}`, {
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Authorization': `Bearer ${this.$data.token}`
        }
      })
        .then(response => {
          console.log(response);
          if (response.ok) {
            console.log('OK');
            return response.json()
          } else {
            console.log('NOT OK');
            return response.json();
          }
        })
        .then(json => {
          console.log('DATA:', json);

          if (json && json.data) {
            // Header cards
            try {
              this.$data.selectedUser.login = json.data.login;
              this.$data.selectedUser.email = json.data.email;
              this.$data.selectedUser.created = json.data.created;
              this.$data.selectedUser.updated = json.data.updated;
              this.$data.selectedUser.balance = json.data.trafficBalanceString;
              this.$data.selectedUser.description = json.data.description;
              this.$data.selectedUser.active = json.data.userStatus == 'ACTIVE' ? true : false;
              this.$data.selectedUser.status = json.data.userStatus;

            } catch(e) {
              console.log('Parsing error', e);
            }
          } else if (json && json.error) {
            console.log(json.error);
          } else {
            console.log('Authentication error');
          }

        })
        .catch((e) => {
          console.log('Fetch error', e);
      });
    }
  },
  beforeMount(){
     this.getFullInfo()
  },
  mounted() {
    salesChart.createChart(this.salesChartID);
    ordersChart.createChart(this.ordersChartID);
  },
};
</script>
