<template>
  <v-card class="card-shadow mt-6 bg-default">
    <div class="card-header-padding card-border-bottom">
      <v-row>
        <v-col>
          <p class="font-weight-600 text-h3 text-white mb-0">Customer Proxy Access info</p>
        </v-col>
        <v-col class="text-right">

          <v-btn
            small
            color="primary"
            class="ma-2"
            dark
            @click="copyToClipboard"
          >
            Copy
            <v-icon
              right
              x-small
            >
              fas fa-copy
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-card-text class="px-0 py-0">
      <v-simple-table class="table bg-default table-border-dark">
        <template v-slot:default>

          <tbody>
            <tr v-for="(item, i) in fields" :key="item.id">
              <td :class="{ 'border-bottom': i != fields.length - 1 }">
                <v-list class="py-0 bg-default">
                  <v-list-item class="px-0 py-4">
                    <v-list-item-avatar :size="48" class="my-0 me-5">
                      <v-img :alt="item.imgSrc" :src="item.imgSrc"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="text-white text-subtitle-2 ls-0 font-weight-600"
                        >{{ item.name }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
              <td
                class="text-white text-h5"
                :class="{ 'border-bottom': i != fields.length - 1 }"
              >
                {{ item.value }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "light-table-transparent",
  data() {
    return {
      fields: [
        {
          id: 0,
          name: "Dashboard URL",
          imgSrc: require("@/assets/img/creds.png"),
          value: "https://dashboard.residential.rayobyte.com/user-area/",
        },
        {
          id: 1,
          name: "Dashboard Login",
          imgSrc: require("@/assets/img/creds.png"),
          value: "Unknown (create or edit user first)",
        },
        {
          id: 2,
          name: "Dashboard Password",
          imgSrc: require("@/assets/img/creds.png"),
          value: "Unknown (create or edit user first)",
        },
        {
          id: 3,
          name: "Proxy Server",
          imgSrc: require("@/assets/img/creds.png"),
          value: "la.residential.rayobyte.com",
        },
        {
          id: 4,
          name: "Proxy Port",
          imgSrc: require("@/assets/img/creds.png"),
          value: "8000",
        },
        {
          id: 5,
          name: "Proxy Login",
          imgSrc: require("@/assets/img/creds.png"),
          value: "Unknown  (create or edit user first)",
        },
        {
          id: 6,
          name: "Proxy Password",
          imgSrc: require("@/assets/img/creds.png"),
          value: "Unknown (create or edit user first)",
        },
      ],
    };
  },
  methods: {
    getCreds: function() {
      const encoded = localStorage.getItem('createdUser');
      if (encoded) {
        try {
          let json = atob(encoded);
          let data = JSON.parse(json);
          this.$data.fields[1].value = data.email;
          this.$data.fields[2].value = data.password;
          this.$data.fields[5].value = data.login;
          this.$data.fields[6].value = data.password;
        } catch(e) {
          console.log(e);
        }
      } else {
        alert('No new users have been created');
      }
    },
    async copyToClipboard() {
      let string = '';
      for (const p of this.$data.fields) {
        string += `
${p.name}
${p.value}
        `;
      }
      await navigator.clipboard.writeText(string.trim());
      console.log('Copied!');
    }
  },
  beforeMount() {
     this.getCreds();
  },
};
</script>
